@font-face {
    font-family: "Barlow Condensed-bold";
    /* src: url("YourFavoriteFont.eot") format("embedded-opentype");
    src: url("YourFavoriteFont.otf") format("opentype"); */
    src: url("./barlow/BarlowCondensed-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Barlow Condensed-regular";
    /* src: url("YourFavoriteFont.eot") format("embedded-opentype");
    src: url("YourFavoriteFont.otf") format("opentype"); */
    src:url("./barlow/BarlowCondensed-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Barlow Condensed-semibold";
    /* src: url("YourFavoriteFont.eot") format("embedded-opentype");
    src: url("YourFavoriteFont.otf") format("opentype"); */
    src: url("./barlow/BarlowCondensed-SemiBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }