.dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: none !important;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}


.dropdown-toggle {
    border-color: white !important;
    background-color: white !important;
}


.no-padding .btn {
    padding: 0px !important;
    background-color: transparent !important;
}

.contendor-arbol .rayado-opciones:nth-child(even){
    background-color:red !important;
}

.rayado-opciones:hover{
    background-color: #e9f8fd;
}
