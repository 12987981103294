.root{
    --altura: 70vh;
}

.login-overlay .panel-body{
    min-height: 244px;
}
.has-feedback{
    position: relative;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.login-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
}
.card-login {
    border: 0;
    width: 100%;
    height: 70vh;
    border-radius: 0 !important;
    /* box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1); */
    box-shadow: 0;
    
    background: black;
}

div >label{color: var(--azul-obscuro) !important;}
div> h5{ color: var(--azul-obscuro); }
div > p {color: var(--azul);}

.bienvenida{
    color:black;
    font-weight: bold;
    background: white;
    height: 70vh;
}
.buttons-box{
    display: flex;
    align-items: center;
    justify-content: center;
}
.blue-gradient-bg{
    height: 100vh;
    background: #00334e;
    /* background: linear-gradient(to right, #0062E6, #3ac1ff); */
    display: flex;
}

.logo{
    width: 50%;
    height: 40%;
    margin-bottom: 20px;
}
