.root{
    --altura: 70vh;
}

.login-overlay .panel-body{
    min-height: 244px;
}
.has-feedback{
    position: relative;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.login-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
}
.card-login {
    border: 0;
    /* width: 100%; */
    height: 70vh;
    border-radius: 0 !important;
    /* box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1); */
    box-shadow: none !important;
    padding: 2rem;
    background: white;
    min-width: 413px;
}

div >label{color: var(--mostaza);}
div> span{ color: var(--azul-obscuro); }
div >span> a {color: var(--mostaza);}

.bienvenida{
    color:black;
    font-weight: bold;
    background: white;
    height: 70vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}
.buttons-box{
    display: flex;
    align-items: center;
    align-items: center;
}
.blue-gradient-bg{
    height: 100vh;
    background: url('../../../../../assets/img/background.png') var(--azul-obscuro) !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    display: flex;
}


@media only screen and (max-width: 800px) {
    /* For mobile phones: */
    
    /* .blue-gradient-bg{
        display: block !important; 
    } */
    .bienvenida{
        display: none !important;
        visibility: hidden;
    }
    .login-wrapper{
        width: 500px !important;
        max-width: 100%;
        height: 457px;
    }
    .card-login {
        padding: 40px !important;
        min-height: 450px !important;
        min-width: 320px !important;
    }
}

.imagen{
    width: 100%;
    
    
}
.logo{
    min-width: 80px;
    width: 40%;
    height: 30%;
    align-self: center;
}