@import "../../../../style/configs/color.css";

.panel-body img{
    max-width: 75%;
    margin-left: 0;
}
.reset-caption{
    font-size: 25px;
    font-weight: bold;
}
.txt{
  margin-bottom: 3%;
  margin-right: 10%;
  margin-left: 10%;
  color: #727c8d;
}
.later{
  margin-right: 5%;
  color: #373a47;
  background-color: #5D6167;
  color: white;
}
.botones{
    margin-bottom: 85px;
}
.alert-permitir{
  width: 950px;
  height: 200px;
}
/*Notificaciones*/
.icono {
  /* padding: 0.25rem;
  border-radius: 0.25rem; */
  height: 92px;
}
.titulo{
  margin-bottom: .4rem;
}
